export const environment = {
    production: true,
    // clientUrl: 'http://localhost:8080/api/client',
    // zoneUrl: 'http://localhost:8080/api/zone',
    // articleUrl: 'http://localhost:8080/api/articles',
    // localidadesUrl: 'http://localhost:8080/api/api',
    // cartUrl: 'http://localhost:8080/api/carts',
    // cartItemUrl: 'http://localhost:8080/api/cartItems',
    // vendorSale: 'http://localhost:8080/api/vendorSales',
    // vendorUrl: 'http://localhost:8080/api/vendors',
    // imageUrl: 'http://localhost:8080/api/images',
    // excelUrl:  'http://localhost:8080/api/excel',
    // userUrl: 'http://localhost:8080/api/users',
    // mercadoPagoUrl: 'http://localhost:8080/api/mercado-pago',
    // paymentUrl: 'http://localhost:8080/api/payments',
    // paymentLinkUrl: 'http://localhost:8080/api/paymentLinks',
    // feePriceUrl: 'http://localhost:8080/api/prices',
    // authUrl: 'http://localhost:8080/api/auth',
    clientUrl: 'https://vps-4562348-x.dattaweb.com/api/client',
    zoneUrl: 'https://vps-4562348-x.dattaweb.com/api/zone',
    articleUrl: 'https://vps-4562348-x.dattaweb.com/api/articles',
    localidadesUrl: 'https://vps-4562348-x.dattaweb.com/api/api',
    cartUrl: 'https://vps-4562348-x.dattaweb.com/api/carts',
    cartItemUrl: 'https://vps-4562348-x.dattaweb.com/api/cartItems',
    vendorSale: 'https://vps-4562348-x.dattaweb.com/api/vendorSales',
    vendorUrl: 'https://vps-4562348-x.dattaweb.com/api/vendors',
    imageUrl: 'https://vps-4562348-x.dattaweb.com/api/images',
    excelUrl:  'https://vps-4562348-x.dattaweb.com/api/excel',
    userUrl: 'https://vps-4562348-x.dattaweb.com/api/users',
    mercadoPagoUrl: 'https://vps-4562348-x.dattaweb.com/api/mercado-pago',
    paymentUrl: 'https://vps-4562348-x.dattaweb.com/api/payments',
    paymentLinkUrl: 'https://vps-4562348-x.dattaweb.com/api/paymentLinks',
    feePriceUrl: 'https://vps-4562348-x.dattaweb.com/api/prices',
    authUrl: 'https://vps-4562348-x.dattaweb.com/api/auth',
    // ordersUrl: ''
  };
